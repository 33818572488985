<template>
  <div>
    <div class="container-fluid center-max-1320-px p-3">
      <div class="col-md-12 d-flex justify-content-center my-5">
        <div class="col-md-8 alert alert-success" role="alert">
          <h3 class="alert-heading">Congratulations!!!</h3>
          <hr />
          <p>Your payment was successful.</p>
          <p>Within few minutes you will receive email with your login credentials.</p>
          <p>Please check your email, download VPN Client and enjoy surfing with privacy!!!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessfulPayment',
  inheritAttrs: false,
};
</script>
